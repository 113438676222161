import { Injectable, signal } from '@angular/core';
import { Displayed } from '@components/header/services/models';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  #displayed = signal<Displayed>({
    header: false,
    menu: false,
    user: false,
    shadow: false,
  });
  displayed = this.#displayed.asReadonly();

  setDisplayed(isDisplayed: Displayed): void {
    this.#displayed.set(isDisplayed);
  }
}
